import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconAlertTriangle } from 'featherico';
import moment from 'moment';
import { useEffect, useState } from 'react';
import AlertBox from '../../components/AlertBox';
import CompanyDateTime from '../../components/DateTime/CompanyDateTime';
import { useAppContext } from '../../hooks/useAppContext';
import replacement from '../../services/Replacement';
import { getAdminBillingUrl } from '../Billing/services/status';
const isVIP = (user) => user.isHR || user.isAssistant || (!user.isGuest && !user.managerId && !!user.reports.length);
const isOnFreePlan = (payment) => payment.plan === 0;
const isAboveFreeLimit = (payment) => payment.userCount > payment.freeLimit;
const isTriallingBeyondFreeLimit = (payment) => isOnFreePlan(payment) && isAboveFreeLimit(payment);
const isTrialExpired = (payment) => payment.trialUntil && Date.now() > payment.trialUntil;
const useTrialExpiryInfo = (user) => {
    const [checkoutUrl, setCheckoutUrl] = useState();
    useEffect(() => {
        getAdminBillingUrl(user).then(setCheckoutUrl);
    }, [user]);
    if (user.company.onAutomatedBilling) {
        return null;
    }
    const { company } = user;
    const { payment } = company;
    if (!isVIP(user) || !isTriallingBeyondFreeLimit(payment) || !isTrialExpired(payment)) {
        return null;
    }
    const daysSinceSignup = moment().diff(moment(company.dateCreated), 'days');
    const daysSinceTrialExpired = payment.trialUntil ? moment().diff(payment.trialUntil, 'days') : null;
    return [daysSinceSignup, daysSinceTrialExpired, payment.userCount, checkoutUrl];
};
const useLicenseExpiryInfo = (user) => {
    const [checkoutUrl, setCheckoutUrl] = useState();
    useEffect(() => {
        getAdminBillingUrl(user).then(setCheckoutUrl);
    }, [user]);
    if (user.company.onAutomatedBilling) {
        return null;
    }
    const { company } = user;
    const { payment } = company;
    if (!isVIP(user) || !payment.paidTo || !payment.paidFrom || isOnFreePlan(payment) || !isAboveFreeLimit(payment)) {
        return null;
    }
    const daysUntilExpiry = moment(payment.paidTo).diff(moment(), 'days');
    if (daysUntilExpiry > payment.warnBeforePaymentExpiry) {
        return null;
    }
    return [daysUntilExpiry, payment.paidFrom, payment.paidTo, checkoutUrl];
};
const TrialExpiredWarning = () => {
    const { user } = useAppContext();
    const expiryInfo = useTrialExpiryInfo(user);
    if (!expiryInfo) {
        return null;
    }
    const [daysSinceSignup, daysSinceTrialExpired, userCount, checkoutUrl] = expiryInfo;
    return (_jsxs(AlertBox, { title: "Your trial has expired", colour: daysSinceTrialExpired && daysSinceTrialExpired > 55 ? 'RED' : 'YELLOW', children: ["Your organization is on the free 10 user plan, but there are now ", userCount, " users in the system, and the trial started ", daysSinceSignup, " days ago already. Please contact", ' ', _jsx("a", { href: "mailto:support@small-improvements.com", children: "support" }), " to extend your trial or to discuss renewal options. Check out our ", _jsx("a", { href: checkoutUrl, children: "checkout page" }), " for the details and discounts!"] }));
};
const LicenseWillExpireWarning = () => {
    const { user } = useAppContext();
    const expiryInfo = useLicenseExpiryInfo(user);
    if (!expiryInfo) {
        return null;
    }
    const [daysUntilExpiry, paidFrom, paidTo, checkoutUrl] = expiryInfo;
    return (_jsxs(AlertBox, { colour: daysUntilExpiry < -28 ? 'RED' : 'YELLOW', title: `Your license ${daysUntilExpiry >= 0 ? 'is about to expire' : 'has expired!'}`, children: ["Your ", replacement('company'), " license is valid from ", _jsx(CompanyDateTime, { date: paidFrom, format: "shortDate" }), ' ', "to ", _jsx(CompanyDateTime, { date: paidTo, format: "shortDate" }), ". Please have a look at the", ' ', _jsx("a", { href: checkoutUrl, children: "checkout page" }), " or contact", ' ', _jsx("a", { href: "mailto:support@small-improvements.com", children: "support" }), " to discuss purchasing options."] }));
};
const LicenseExpiredBanner = () => {
    const { user } = useAppContext();
    const expiryInfo = useLicenseExpiryInfo(user);
    if (!expiryInfo) {
        return null;
    }
    const [daysUntilExpiry, , , checkoutUrl] = expiryInfo;
    if (daysUntilExpiry > -28) {
        return null;
    }
    return (_jsx("div", { className: "boxWarning broadcast", children: _jsxs("div", { children: [_jsxs("p", { children: [_jsx(IconAlertTriangle, {}), "Your license expired ", Math.abs(daysUntilExpiry), " days ago!"] }), "After 60 days your users will no longer be able to log in! Please have a look at the", ' ', _jsx("a", { href: checkoutUrl, children: "checkout page" }), " or contact", ' ', _jsx("a", { href: "mailto:support@small-improvements.com", children: "support" }), " to discuss purchasing options"] }) }));
};
export { LicenseExpiredBanner, LicenseWillExpireWarning, TrialExpiredWarning };
